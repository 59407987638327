import request from "@/utils/request";

//导入员工工资单
export function uploadResume(data) {
  return request({
    url: "/sys/salary/importData",
    method: "post",
    data: data,
  });
}

//查看员工工资单列表
export function salaryList(params) {
  return request({
    url: "/sys/salary/list",
    method: "get",
    params: params,
  });
}
//发送工资单
export function sendSalary(params) {
  return request({
    url: "/sys/salary/sendSalary",
    method: "get",
    params: params,
  });
}
//查看员工个人工资单列表
export function queryMySalary(params) {
  return request({
    url: "/staff/queryMySalary",
    method: "get",
    params: params,
  });
}
//查看员工个人工资单详情
export function querySalaryInfo(params) {
  return request({
    url: `/staff/querySalaryInfo/${params.salaryMonth}`,
    method: "get",
    params: params,
  });
}
/**
 * @description: 个人查看工资单密码验证
 * @param {Number} password
 */

export function salaryPwdValid(params) {
  return request({
    method: "GET",
    url: "/staff/salaryPwdValid",
    params: params,
  });
}
/**
 * @description: 查询工资单有效期是否失效
 *
 */

export function pwdExpired(params) {
  return request({
    method: "GET",
    url: "/sys/salary/pwdExpired",
    params: params,
  });
}

<!--  -->
<template>
  <div class="salary-detail-page">
    <!-- <TopWrapper :topObj="topObj" /> -->
    <div class="main-wrapper" v-if="salaryInfo">
      <div class="top-react">
        <p class="wellcome-msg">{{ salaryInfo.userName }}辛苦了</p>
        <p class="amount">￥{{ salaryInfo.thisSalary }}</p>
        <p class="remark">实发工资</p>
      </div>
      <div class="notice-box">
        <van-notice-bar wrapable left-icon="info-o" mode="closeable">
          <p class="title">温馨提示</p>
          <p>工资条属于敏感信息，请注意保密</p>
        </van-notice-bar>
      </div>
      <div class="cell-item">
        <p class="label">身份证</p>
        <p class="value">{{ salaryInfo.idCard }}</p>
      </div>
      <div class="cell-item">
        <p class="label">性别</p>
        <p class="value">{{ salaryInfo.staffGender }}</p>
      </div>
      <div class="cell-item">
        <p class="label">部门</p>
        <p class="value">{{ salaryInfo.departmentName }}</p>
      </div>
      <div class="cell-item">
        <p class="label">岗位</p>
        <p class="value">{{ salaryInfo.postName }}</p>
      </div>
      <div class="cell-item">
        <p class="label">序列类型</p>
        <p class="value">{{ salaryInfo.serialType }}</p>
      </div>
      <div class="cell-item">
        <p class="label">职级</p>
        <p class="value">{{ salaryInfo.rankLevel }}</p>
      </div>
      <div class="cell-item">
        <p class="label">职段</p>
        <p class="value">{{ salaryInfo.rankPar }}</p>
      </div>
      <div class="cell-item">
        <p class="label">入司时间</p>
        <p class="value">{{ salaryInfo.startTime }}</p>
      </div>
      <div class="cell-item">
        <p class="label">转正日期</p>
        <p class="value">{{ salaryInfo.becomeTime }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">实际工资</p>
        <p class="value">{{ salaryInfo.practicalSalary }}</p>
      </div>
      <div class="cell-item gray no-line">
        <p class="label">岗位工资标准</p>
      </div>

      <div class="cell-item no-line">
        <p class="label">基本工资(标准)</p>
        <p class="value">{{ salaryInfo.basePayStand }}</p>
      </div>

      <div class="cell-item no-line">
        <p class="label">绩效工资(标准)</p>
        <p class="value">{{ salaryInfo.meritPayStand }}</p>
      </div>
      <!-- 考评/系数 -->
      <div class="cell-item gray no-line">
        <p class="label">考评/系数</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">考核结果</p>
        <p class="value">{{ salaryInfo.checkResult }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">绩效考核系数</p>
        <p class="value">{{ salaryInfo.checkCoeff }}</p>
      </div>
      <!-- 岗位工资 -->
      <div class="cell-item gray no-line">
        <p class="label">岗位工资</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">基本工资</p>
        <p class="value">{{ salaryInfo.basePay }}</p>
      </div>

      <div class="cell-item no-line">
        <p class="label">绩效工资</p>
        <p class="value">{{ salaryInfo.meritPay }}</p>
      </div>

      <!-- 津补贴 -->
      <div class="cell-item gray no-line">
        <p class="label">津补贴</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">司龄补贴</p>
        <p class="value">{{ salaryInfo.senioritySalary }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">交通补贴</p>
        <p class="value">{{ salaryInfo.travelAllowance }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">住房补贴</p>
        <p class="value">{{ salaryInfo.rentalAllowance }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">通讯补贴</p>
        <p class="value">{{ salaryInfo.phoneAllowance }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">卫生费补贴</p>
        <p class="value">{{ salaryInfo.sanitationFee }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">职称补贴</p>
        <p class="value">{{ salaryInfo.positionSubsidy }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">降温取暖补贴</p>
        <p class="value">{{ salaryInfo.temperatureAllowance }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">用餐补贴</p>
        <p class="value">{{ salaryInfo.diningSubsidy }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">置装补贴</p>
        <p class="value">{{ salaryInfo.clothingSubsidy }}</p>
      </div>
      <!-- 奖励 -->
      <div class="cell-item no-line">
        <p class="label">奖励</p>
        <p class="value">{{ salaryInfo.award }}</p>
      </div>
      <!-- 奖金 -->
      <div class="cell-item gray no-line">
        <p class="label">奖金</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">项目支持奖金</p>
        <p class="value">{{ salaryInfo.serviceSupport }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">项目结案奖金</p>
        <p class="value">{{ salaryInfo.projectClosure }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">项目补贴</p>
        <p class="value">{{ salaryInfo.projectAllowance }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">优秀研究成果奖金</p>
        <p class="value">
          {{ salaryInfo.outstandingAchievements }}
        </p>
      </div>
      <div class="cell-item">
        <p class="label">年终奖金</p>
        <p class="value">{{ salaryInfo.yearBonus }}</p>
      </div>
      <!-- 其他扣款 -->
      <div class="cell-item">
        <p class="label">请假扣款</p>
        <p class="value">{{ salaryInfo.leaveMoney }}</p>
      </div>
      <div class="cell-item">
        <p class="label">值班补贴</p>
        <p class="value">{{ salaryInfo.dutySubsidy }}</p>
      </div>
      <div class="cell-item">
        <p class="label">纠错工资</p>
        <p class="value">{{ salaryInfo.errorRecovery }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">合计</p>
        <p class="value">{{ salaryInfo.totalMoney }}</p>
      </div>
      <!-- 扣款 -->
      <div class="cell-item gray no-line">
        <p class="label">扣款</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">养老保险</p>
        <p class="value">{{ salaryInfo.endowmentInsurance }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">医疗保险</p>
        <p class="value">{{ salaryInfo.medicalInsurance }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">救助医疗/年度</p>
        <p class="value">{{ salaryInfo.otherMedical }}</p>
      </div>
      <div class="cell-item no-line">
        <p class="label">失业保险</p>
        <p class="value">
          {{ salaryInfo.unemploymentInsurance }}
        </p>
      </div>
      <div class="cell-item no-line">
        <p class="label">公积金</p>
        <p class="value">{{ salaryInfo.accumulationFund }}</p>
      </div>
      <div class="cell-item">
        <p class="label">个税</p>
        <p class="value">{{ salaryInfo.individualIncomeTax }}</p>
      </div>
      <div class="cell-item">
        <p class="label">本次实发</p>
        <p class="value">{{ salaryInfo.thisSalary }}</p>
      </div>
    </div>
    <van-empty
      style="background: #fff; min-height: 15rem"
      v-else
      description="暂无数据"
    />
    <van-dialog
      v-model="dialogPayroll"
      :show-cancel-button="true"
      title="输入密码"
      :beforeClose="handleConfirm"
    >
      <van-field
        v-model="password"
        placeholder="请输入密码"
        clearable
        autosize
        maxlength="20"
        type="password"
      />
    </van-dialog>
  </div>
</template>

<script>
import TopWrapper from "@/components/topWrapper/index.vue";
import { querySalaryInfo, pwdExpired } from "@/api/salary/index.js";
export default {
  data() {
    return {
      topObj: {
        bgColor: "#fff",
        title: "工资单",
        txtColor: "#000",
        isBack: true,
        isMsg: false,
      },
      salaryInfo: null,
      dialogPayroll: false,
      id: "",
      password: "",
    };
  },
  filters: {
    filterSalay(val) {
      return (val / 100).toFixed(2);
    },
  },

  components: { TopWrapper },

  computed: {},

  mounted() {
    if (this.$route.query) {
      let { id } = this.$route.query;
      if (id) {
        this.topObj.title = `${id}工资单`;
        this.id = id;
        this.getpwdExpired(id);
      }
    }
  },

  methods: {
    getpwdExpired(id) {
      pwdExpired().then((res) => {
        if (res) {
          let { data, success } = res;
          if (success) {
            this.dialogPayroll = !data;
            if (data) {
              this.getSalaryInfo(id);
            }
          }
        }
      });
    },
    handleConfirm(action, done) {
      if (action != "confirm") {
        done();
        return;
      }
      if (!this.password) {
        Toast("密码不能为空");

        done(false);
        return;
      }
      this.getSalaryInfo(this.id);
      done();
    },
    getSalaryInfo(id) {
      querySalaryInfo({
        salaryMonth: id,
        password: this.password,
      }).then((res) => {
        if (res) {
          let { code, data } = res;
          if (code == 200) {
            this.salaryInfo = data;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.salary-detail-page {
  width: 100%;
  height: auto;
  min-height: 100%;
  background: #fafafa;
  // position: relative;

  .main-wrapper {
    width: 100%;
    position: relative;

    .top-react {
      width: 100%;
      height: 4rem;
      background: #0094ee;
      color: #fff;
      padding: 0.3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .wellcome-msg {
        margin-bottom: 0.2rem;
        font-size: 0.3rem;
      }
      .amount {
        margin-bottom: 0.2rem;
        font-size: 0.6rem;
        font-weight: 700;
      }

      .remark {
        margin-top: 0.1rem;
      }
    }
    .notice-box {
      padding: 0.3rem;
    }
    .cell-item {
      background: #fff;
      padding: 0.3rem;
      margin-bottom: 0.2rem;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      .label {
        width: 2.2rem;
      }
      .value {
        flex: 1;
        text-align: right;
      }
    }
    .gray {
      background: #eee;
    }
    .no-line {
      margin-bottom: 0rem;
    }
  }
}
</style>
